import React, { ChangeEvent, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  CardContent,
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
  Checkbox,
  Stack,
  IconButton,
  Chip,
} from "@mui/material";
import { Box, spacing } from "@mui/system";
import DropZone from "src/components/DropZone";
import LocalEnhancedTable from "src/components/localTable/LocalTable";
import {
  ISysAdminDocument,
  SysDocuments,
  IUploadDocumentTech,
  TableData,
} from "src/ts/interfaces";
import { ColumnType } from "src/types/enhancedTable";
import DialogMessagePopup from "src/components/DialogMessagePopup";
import FileUtils from "src/utils/file";
import useLog from "src/hooks/useLog";
import { GridActionButton } from "src/components/gridControls";
import { useParams } from "react-router-dom";
import { CompanyService, SubscriptionService, userService } from "src/services";
import { FormButton, FormText } from "src/components/formControls";
import { AddIcon, SendIcon } from "src/components/icons";
import AddNewPhotoPopUp from "./components/AddNewPhotoPopUp";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import ShareByEmailPopUpComponent from "src/components/others/ShareByEmailPopUpComponent";
import { ROLES } from "src/constants";
import { useAuth, usePermissions } from "src/hooks";
import userDocumentService from "src/services/userDocumentService";
import DocumentIssuePopUp from "./components/DocumentIssuePopUp";
import { Permission as PermissionTypes } from "src/ts/enums";
import accountService from "src/services/accountService";
import roleService from "src/services/roleService";
const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const getColumns = (
  handleDeleteDocument: (row: ISysAdminDocument) => void,
  downloadDocument: (row: ISysAdminDocument) => void,
  handleOnChangeCheck: (row: ISysAdminDocument) => void,
  fullAccess: boolean
): ColumnType[] => [
  {
    id: "name",
    label: "Name",
    type: "custom",
    sort: true,
    width: "75%",
    callback: (row: IUploadDocumentTech) => {
      return (
        <>
          <Stack direction="row">
            {row.type === "covidcard" ? (
              <ImageOutlinedIcon />
            ) : (
              <PictureAsPdfOutlinedIcon />
            )}{" "}
            {row.name}
          </Stack>
        </>
      );
    },
  },
  {
    id: "status",
    label: "Actions",
    type: "custom",
    sort: false,
    callback: (row: ISysAdminDocument) => {
      return (
        <div>
          <Box>
            <GridActionButton
              type="download"
              onClick={() => downloadDocument(row)}
              tooltip="Download"
            />
            {fullAccess && !row.disabled && (
              <GridActionButton
                type="delete"
                onClick={() => handleDeleteDocument(row)}
                tooltip="Delete"
              />
            )}
          </Box>
        </div>
      );
    },
  },
  {
    id: "email",
    label: "Email",
    type: "custom",
    sort: false,
    callback: (row: ISysAdminDocument) => {
      return (
        <div>
          <Box mr={2}>
            <Checkbox
              onChange={() => {
                handleOnChangeCheck(row);
              }}
            />
          </Box>
        </div>
      );
    },
  },
];

const getColumnsTechnician = (
  handleDeleteDocument: (row: IUploadDocumentTech) => void,
  downloadDocument: (row: IUploadDocumentTech) => void,
  handleOnChangeCheck: (row: IUploadDocumentTech) => void,
  onClickModal: (row: IUploadDocumentTech) => void,
  fullAccess: boolean
): ColumnType[] => [
  {
    id: "certificationType",
    label: "Certification Type",
    type: "custom",
    sort: true,
    width: "50%",
    callback: (row: IUploadDocumentTech) => {
      return (
        <>
          <Typography>
            {row.certificationType ===
            "Testing & Balancing Technician Certification" ? (
              <>
                {" "}
                <Chip
                  label={row.certificationType}
                  color="primary"
                  size="small"
                  sx={{ width: "270px" }}
                />
              </>
            ) : (
              <>
                {" "}
                <Chip
                  label={row.certificationType}
                  color="success"
                  size="small"
                  sx={{ width: "270px" }}
                />
              </>
            )}
          </Typography>
        </>
      );
    },
  },
  {
    id: "idType",
    label: "Status",
    type: "custom",
    sort: false,
    align: "center",
    callback: (row: IUploadDocumentTech) => {
      return (
        <>
          {row.certification === null ? (
            <Typography textAlign="center">In Progress</Typography>
          ) : (
            <Stack>
              <Typography textAlign="center" mr={3}>
                Certified{" "}
              </Typography>
              <Typography textAlign="center" mr={3}>
                ({row.certification}){" "}
              </Typography>
            </Stack>
          )}
        </>
      );
    },
  },
  {
    id: "name",
    label: "Name",
    type: "custom",
    sort: false,
    callback: (row: IUploadDocumentTech) => {
      return (
        <>
          <Stack direction="row" ml={2}>
            <ImageOutlinedIcon /> {row.name}
          </Stack>
        </>
      );
    },
  },
  {
    id: "status",
    label: "Actions",
    type: "custom",
    sort: false,
    callback: (row: IUploadDocumentTech) => {
      return (
        <div>
          <Box mr={2}>
            {row.name === "" ? null : (
              <GridActionButton
                type="download"
                onClick={() => downloadDocument(row)}
                tooltip="Download"
              />
            )}
            {row.name === "" ? null : row.certification === null &&
              fullAccess ? (
              <GridActionButton
                type="delete"
                onClick={() => handleDeleteDocument(row)}
                tooltip="Delete"
              />
            ) : null}
            {row.name !== "" ? null : (
              <>
                {fullAccess && (
                  <IconButton
                    aria-label="upload picture"
                    component="label"
                    onClick={() => onClickModal(row)}
                  >
                    <AddIcon />
                  </IconButton>
                )}
              </>
            )}
          </Box>
        </div>
      );
    },
  },
  {
    id: "email",
    label: "Email",
    type: "custom",
    sort: false,
    width: "10%",
    callback: (row: IUploadDocumentTech) => {
      return (
        <div>
          <Box mr={2}>
            <Checkbox
              disabled={row.name === "" ? true : false}
              onChange={() => {
                handleOnChangeCheck(row);
              }}
            />
          </Box>
        </div>
      );
    },
  },
];

const getColumnsTechnicianID = (
  handleDeleteDocument: (row: IUploadDocumentTech) => void,
  downloadDocument: (row: IUploadDocumentTech) => void,
  handleOnChangeCheck: (row: IUploadDocumentTech) => void,
  onChangeInput: (
    e: React.ChangeEvent<HTMLInputElement>,
    row: IUploadDocumentTech
  ) => void,
  fullAccess: boolean
): ColumnType[] => [
  {
    id: "certificationType",
    label: "Certification Type",
    type: "custom",
    sort: true,
    width: "22%",
    callback: (row: IUploadDocumentTech) => {
      return (
        <>
          <Typography>
            {row.certificationType ===
            "Testing & Balancing Technician Certification" ? (
              <>
                {" "}
                <Chip
                  label={row.certificationType}
                  color="primary"
                  size="small"
                  sx={{ width: "270px" }}
                />
              </>
            ) : (
              <>
                {" "}
                <Chip
                  label={row.certificationType}
                  color="success"
                  size="small"
                  sx={{ width: "270px" }}
                />
              </>
            )}
          </Typography>
        </>
      );
    },
  },
  {
    id: "idType",
    label: "Status",
    type: "custom",
    sort: false,

    align: "center",
    callback: (row: IUploadDocumentTech) => {
      return (
        <>
          {row.certification === null ? (
            <Typography>In Progress</Typography>
          ) : (
            <Stack>
              <Typography textAlign="center" mr={3}>
                Certified{" "}
              </Typography>
              <Typography textAlign="center" mr={3}>
                ({row.certification}){" "}
              </Typography>
            </Stack>
          )}
        </>
      );
    },
  },
  {
    id: "idType",
    label: "ID Type",
    type: "string",
    sort: true,
  },
  {
    id: "idNumber",
    label: " ID Number",
    type: "string",
    sort: true,
  },
  {
    id: "name",
    label: "Name",
    type: "custom",
    sort: true,
    callback: (row: IUploadDocumentTech) => {
      return (
        <>
          <Stack direction="row">
            <PictureAsPdfOutlinedIcon /> {row.name}
          </Stack>
        </>
      );
    },
  },
  {
    id: "status",
    label: "Actions",
    type: "custom",
    sort: false,
    callback: (row: IUploadDocumentTech) => {
      return (
        <div>
          <Box mr={2}>
            {row.name === "" ? null : (
              <GridActionButton
                type="download"
                onClick={() => downloadDocument(row)}
                tooltip="Download"
              />
            )}
            {row.name === "" ? null : row.certification === null &&
              fullAccess ? (
              <GridActionButton
                type="delete"
                onClick={() => handleDeleteDocument(row)}
                tooltip="Delete"
              />
            ) : null}
            {row.name !== "" ? null : (
              <>
                {fullAccess && (
                  <IconButton aria-label="upload picture" component="label">
                    <input
                      hidden
                      accept="application/pdf"
                      type="file"
                      onChange={(e) => {
                        onChangeInput(e, row);
                      }}
                    />
                    <AddIcon />
                  </IconButton>
                )}
              </>
            )}
          </Box>
        </div>
      );
    },
  },
  {
    id: "email",
    label: "Email",
    type: "custom",
    sort: false,
    callback: (row: IUploadDocumentTech) => {
      return (
        <div>
          <Box mr={2}>
            <Checkbox
              disabled={row.name === "" ? true : false}
              onChange={() => {
                handleOnChangeCheck(row);
              }}
            />
          </Box>
        </div>
      );
    },
  },
];

enum SysAdminDocumentTypeEnum {
  Certification = "certification",
  Document = "document",
  CovidCard = "covidcard",
  Photo = "photo",
  ID = "id",
}

type UserDocumentParams = {
  userId: string;
  companyId: string;
};

const PersonalDocuments = () => {
  const { user } = useAuth();
  const { log } = useLog();
  let { userId, companyId } = useParams<UserDocumentParams>();
  const [currentRow, setCurrentRow] = useState<ISysAdminDocument>();
  const [currentRowTechnician, setCurrentRowTechnician] =
    useState<IUploadDocumentTech>();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAddPhotoModal, setShowAddPhotoModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [refreshPage, setRefreshPage] = useState(false);

  const [certifications, setCertifications] = useState<SysDocuments[]>([]);
  const [documents, setDocuments] = useState<SysDocuments[]>([]);
  const [acceptance, setAcceptance] = useState<SysDocuments>();
  const [covidCard, setCovidCard] = useState<SysDocuments[]>([]);
  const [photoTech, setPhotoTech] = useState<IUploadDocumentTech[]>([]);
  const [identificationTech, setIdentificationTech] = useState<
    IUploadDocumentTech[]
  >([]);

  const [userDocuments, setUserDocuments] = useState<IUploadDocumentTech[]>([]);
  const [sendbyEmailShow, setSendbyEmailShow] = useState(false);
  const [emailSelected, setEmailSelected] = useState<Array<number>>([]);
  const [disabledButton, setDisabledButton] = useState(false);
  const [documentIssueShowPopUp, setDocumentIssueShowPopUp] = useState(false);
  const { read } = usePermissions(PermissionTypes.Union_Card);
  const [values, setValues] = useState({ code: "" });
  const { fullAccess: fullAccessUser } = usePermissions(
    PermissionTypes.Users_Documents
  );
  const [isTechnician, setIsTechnician] = useState(false);
  let navigate = useNavigate();
  useEffect(() => {
    const loadDocuments = async () => {
      const hasTechnicianRole = await roleService.hasTechnicianRole(
        userId ?? ""
      );
      setIsTechnician(hasTechnicianRole.data);
      const responesCompany = await CompanyService.companyUser(user!.companyId);

      const subscription = await SubscriptionService.getById(
        responesCompany.data.subscriptionId
      );

      if (subscription.data.type === "School") {
        const docsubscription: SysDocuments = {
          id: 0,
          type: "document",
          name: "SubscriptionandUseAgreementAcceptance.pdf",
          size: 0,
          userId: 0,
          technicianId: 0,
          path: responesCompany.data.agreementAcceptanceUrl,
          createdDate: new Date(),
          certification: "",
          certificationType: "",
          idType: null,
          idNumber: null,
          disabled: true,
        };
        setAcceptance(docsubscription);
        setRefreshPage(!refreshPage);
      }
    };
    loadDocuments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const loadDocuments = async () => {
      try {
        const responseDocuments = await userDocumentService.getAllUserDocuments(
          Number(userId)
        );
        setCertifications(
          responseDocuments.data.filter(
            (item: SysDocuments) =>
              item.type === SysAdminDocumentTypeEnum.Certification
          )
        );

        const listDocuments = responseDocuments.data.filter(
          (item: SysDocuments) =>
            item.type === SysAdminDocumentTypeEnum.Document
        );
        console.log(acceptance);
        console.log(documents);
        console.log(listDocuments);
        if (acceptance) setDocuments([...listDocuments, acceptance]);
        else setDocuments([...listDocuments]);
        setCovidCard(
          responseDocuments.data.filter(
            (item: SysDocuments) =>
              item.type === SysAdminDocumentTypeEnum.CovidCard
          )
        );
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    };
    const getDocumentTech = async () => {
      var userId2 = Number(userId);
      try {
        const response = await userService.getUserDocumentaionTecn(userId2);
        setUserDocuments(response.data);

        const photoTechn = response.data.filter((item: IUploadDocumentTech) => {
          return item.type === "photo";
        });

        setPhotoTech(photoTechn);
        const idTech = response.data.filter((item: IUploadDocumentTech) => {
          return item.type === "id";
        });
        setIdentificationTech(idTech);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    };

    loadDocuments();
    getDocumentTech();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshPage]);

  useEffect(() => {
    setValues({
      code: "",
    });
  }, [showDeleteModal]);

  const handleDeleteDocument = (row: ISysAdminDocument) => {
    setCurrentRow(row);
    setShowDeleteModal(true);
  };

  const handleDeleteDocumentTechnician = (row: IUploadDocumentTech) => {
    setCurrentRowTechnician(row);
    setShowDeleteModal(true);
  };

  const downloadDocument = async (row: ISysAdminDocument) => {
    if (row && row.id) {
      try {
        setIsSubmitting(true);

        const response = await userDocumentService.downloadUserFile(
          Number(row.id)
        );

        let fileName = row.name;
        FileUtils.downloadFile(response.data, fileName);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      } finally {
        setIsSubmitting(false);
      }
    } else {
      const response = await CompanyService.downloadAcceptanceFile(
        user!.companyId ?? 0
      );
      let fileName = row.name;
      FileUtils.downloadFile(response.data, fileName);
    }
  };

  const downloadDocumentTechnician = async (row: IUploadDocumentTech) => {
    if (row && row.id) {
      try {
        setIsSubmitting(true);

        const response = await userDocumentService.downloadUserFile(
          Number(row.id)
        );

        let fileName = row.name;

        FileUtils.downloadFile(response.data, fileName);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      } finally {
        setIsSubmitting(false);
      }
    } else {
    }
  };

  const handleCertificationFileAccepted = async (file: File) => {
    var userId2 = userId;
    await saveFile(file, SysAdminDocumentTypeEnum.Certification, userId2 ?? "");
  };

  const handleDocumentFileAccepted = async (file: File) => {
    var userId2 = userId;
    await saveFile(file, SysAdminDocumentTypeEnum.Document, userId2 ?? "");
  };

  const handleCovidCardFileAccepted = async (file: File) => {
    var userId2 = userId;
    await saveFile(file, SysAdminDocumentTypeEnum.CovidCard, userId2 ?? "");
  };

  const saveFile = async (file: File, fileType: string, userId: string) => {
    try {
      setIsSubmitting(true);
      await userDocumentService.uploadUserFile({
        documentType: fileType,
        content: file,
        technicianId: "0",
        userId: userId,
      });
      setRefreshPage(!refreshPage);
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    } finally {
      setIsSubmitting(false);
    }
  };

  const deleteDocument = async () => {
    if (
      (currentRow && currentRow.id) ||
      (currentRowTechnician && currentRowTechnician.id)
    ) {
      try {
        setIsSubmitting(true);
        if (currentRow?.id === undefined) {
          await userDocumentService.deleteUserFile(
            currentRowTechnician?.id.toString() ?? ""
          );
        } else {
          await userDocumentService.deleteUserFile(
            currentRow?.id.toString() ?? ""
          );
        }

        setRefreshPage(!refreshPage);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      } finally {
        setIsSubmitting(false);
      }
    } else {
    }
  };

  const handleOnChangeCheck = (row: ISysAdminDocument) => {
    if (emailSelected.length === 0) {
      setEmailSelected([...emailSelected, row.id]);
    } else {
      const emailSelectedFilter = emailSelected.filter((item: number) => {
        return item === row.id;
      });
      if (emailSelectedFilter[0] === row.id) {
        setEmailSelected(
          emailSelected.filter((item: number) => item !== row.id)
        );
      } else {
        setEmailSelected([...emailSelected, row.id]);
      }
    }
  };

  const handleOnChangeCheckTechnician = (row: IUploadDocumentTech) => {
    if (emailSelected.length === 0) {
      setEmailSelected([...emailSelected, row.id]);
    } else {
      const emailSelectedFilter = emailSelected.filter((item: number) => {
        return item === row.id;
      });
      if (emailSelectedFilter[0] === row.id) {
        setEmailSelected(
          emailSelected.filter((item: number) => item !== row.id)
        );
      } else {
        setEmailSelected([...emailSelected, row.id]);
      }
    }
  };

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setValues({
      ...values,
      [name]: value,
    });
    value === "delete" ? setDisabledButton(true) : setDisabledButton(false);
  };

  const onClickModal = (row: IUploadDocumentTech) => {
    setShowAddPhotoModal(true);
    setCurrentRowTechnician(row);
  };

  const columns = getColumns(
    handleDeleteDocument,
    downloadDocument,
    handleOnChangeCheck,
    user?.userId.toString() === userId || fullAccessUser
  );

  const saveFileTechn = async (
    file: File,
    fileType: string,
    userId: string,
    technicianId: string
  ) => {
    try {
      setIsSubmitting(true);
      await userDocumentService.uploadUserFile({
        documentType: fileType,
        content: file,
        technicianId: technicianId,
        userId: userId,
      });
      setRefreshPage(!refreshPage);
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handlePhotoFileAccepted = async (
    file: File,
    row: IUploadDocumentTech
  ) => {
    await saveFileTechn(
      file,
      SysAdminDocumentTypeEnum.ID,
      row?.userId ?? "",
      row?.technicianId ?? ""
    );
  };

  const onChangeInput = (
    e: React.ChangeEvent<HTMLInputElement>,
    row: IUploadDocumentTech
  ) => {
    const file = e.target.files?.[0] || null;
    if (file !== null) {
      handlePhotoFileAccepted(file, row);
      setRefreshPage(true);
    }
  };

  const columnsTechnician = getColumnsTechnician(
    handleDeleteDocumentTechnician,
    downloadDocumentTechnician,
    handleOnChangeCheckTechnician,
    onClickModal,
    user?.userId.toString() === userId || fullAccessUser
  );

  const columnsTechnicianID = getColumnsTechnicianID(
    handleDeleteDocumentTechnician,
    downloadDocumentTechnician,
    handleOnChangeCheckTechnician,
    onChangeInput,
    user?.userId.toString() === userId || fullAccessUser
  );

  const handleSendEmail = async (dataTable: TableData[]) => {
    const teamMemberIds = dataTable
      .filter((item) => item.userType === "TeamMember")
      .map((item) => item.id) as number[];

    const userCompanyIds = dataTable
      .filter((item) => item.userType === "LicenseedUser")
      .map((item) => item.id) as number[];

    await userDocumentService.sendEmailDocuments({
      documents: emailSelected,
      teamMemberEmails: teamMemberIds,
      userEmails: userCompanyIds,
    });
  };

  const handlerCloser = () => {
    switch (user?.role) {
      case ROLES.Auditor:
        navigate("/app/auditorDashboard");
        break;
      case ROLES.SysAdmin:
        navigate("/app/sysAdminDashboard");
        break;
      case ROLES.EnergyAuditor:
        navigate("/app/energyAuditorDashboard");
        break;
      case ROLES.TemporalEnergyAuditor:
        navigate("/app/temporalEnergyAuditorDashboard");
        break;
      case ROLES.TemporalAuditor:
        navigate("/app/temporalAuditorDashboard");
        break;
      case ROLES.CompanyOwner:
        navigate(`/app/Technicians/${companyId}`);
        break;
      case ROLES.Administrator:
        navigate(`/app/Technicians/${companyId}`);
        break;
      case ROLES.ProjectManager:
        navigate(`/app/Technicians/${companyId}`);
        break;
      case ROLES.SuperAdministrator:
        navigate(`/app/Technicians/${companyId}`);
        break;
      default:
        navigate("/private");
        break;
    }
  };

  return (
    <>
      <Helmet title="User Documentation" />
      <Typography variant="h3" gutterBottom display="inline">
        User Documentation
      </Typography>
      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Dashboard
        </Link>
        <Typography>User Documentation</Typography>
      </Breadcrumbs>
      <Divider my={6} />
      <Grid container spacing={6}>
        <Grid item xs={12}>
          {userDocuments.length > 0 && isTechnician && (
            <>
              <Card mb={6}>
                <CardContent>
                  <Stack direction="row" spacing={2}>
                    <Typography variant="h6" gutterBottom display={"inline"}>
                      Photo
                    </Typography>
                    <Typography variant="subtitle2" display={"inline"}>
                      {" "}
                      (This will be your certification photo uploaded into every
                      AMP certification you're awarded)
                    </Typography>
                  </Stack>

                  <Grid container spacing={6}>
                    <Grid item md={12}>
                      <LocalEnhancedTable<IUploadDocumentTech>
                        refreshGrid={false}
                        columns={columnsTechnician}
                        data={photoTech}
                        hidePagination={true}
                      />
                    </Grid>
                    <Grid item md={12}>
                      <Typography variant="subtitle1">
                        <b>
                          *File must be in PNG, JPG, JPEG, GIF or BMP format*
                        </b>
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>

              <Card mb={6}>
                <CardContent>
                  <Stack direction="row" spacing={2}>
                    <Typography variant="h6" gutterBottom display={"inline"}>
                      Identification
                    </Typography>
                    <Typography variant="subtitle2" display={"inline"}>
                      {" "}
                      (This will be your proof of identity for AMP such as a
                      driver’s license, passport, etc.)
                    </Typography>
                  </Stack>

                  <Grid container spacing={6}>
                    <Grid item md={12}>
                      <LocalEnhancedTable<IUploadDocumentTech>
                        refreshGrid={false}
                        columns={columnsTechnicianID}
                        data={identificationTech}
                        hidePagination={true}
                      />
                    </Grid>
                    <Grid item md={4}>
                      <Typography variant="subtitle1">
                        <b>*File must be in PDF format*</b>
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </>
          )}

          {isTechnician && (
            <Card mb={6}>
              <CardContent>
                <Stack direction="row" spacing={2}>
                  <Typography variant="h6" gutterBottom display={"inline"}>
                    Certification(s)
                  </Typography>
                  <Typography variant="subtitle2" display={"inline"}>
                    {" "}
                    (This is for any additional certifications that were awarded
                    to you personally and additional complimentary documents you
                    would like to have included in every report in conjunction
                    with your certification i.e. diplomas, certificates of
                    completion, etc.)
                  </Typography>
                </Stack>

                <Grid container spacing={6}>
                  <Grid item md={12}>
                    <LocalEnhancedTable<SysDocuments>
                      refreshGrid={false}
                      columns={columns}
                      data={certifications}
                      hidePagination={true}
                    />
                    {fullAccessUser && (
                      <DropZone
                        onFileAccepted={handleCertificationFileAccepted}
                        accept={{
                          "application/pdf": [".pdf"],
                        }}
                        disabled={false}
                        disableMessage="File must be in PDF format"
                        submiting={isSubmitting}
                      />
                    )}
                  </Grid>
                  <Grid item md={4}>
                    <Typography variant="subtitle1">
                      <b>*File must be in PDF format*</b>
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          )}

          <Card mb={6}>
            <CardContent>
              <Stack direction="row" spacing={2}>
                <Typography variant="h6" gutterBottom display={"inline"}>
                  Document(s)
                </Typography>
                <Typography variant="subtitle2" display={"inline"}>
                  {" "}
                  (This is for your records and project availability when asked.
                  This will not be included in your reports.)
                </Typography>
              </Stack>

              <Grid container spacing={6}>
                <Grid item md={12}>
                  <LocalEnhancedTable<SysDocuments>
                    refreshGrid={false}
                    columns={columns}
                    data={documents}
                    hidePagination={true}
                  />
                  {fullAccessUser && (
                    <DropZone
                      onFileAccepted={handleDocumentFileAccepted}
                      accept={{ "application/pdf": [".pdf"] }}
                      disabled={false}
                      disableMessage="File must be in PDF format"
                      submiting={isSubmitting}
                    />
                  )}
                </Grid>
                <Grid item md={6}>
                  <Typography variant="subtitle1">
                    <b>*File must be in PDF format*</b>
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          {read && process.env.REACT_APP_ENVIRONMENT !== "SCHOOL" && (
            <Card mb={6}>
              <CardContent>
                <Stack direction="row" spacing={2}>
                  <Typography variant="h6" gutterBottom display={"inline"}>
                    Union Card
                  </Typography>
                  <Typography variant="subtitle2" display={"inline"}>
                    {" "}
                    (This is for your records and project availability when
                    asked. This will not be included in your reports.)
                  </Typography>
                </Stack>

                <Grid container spacing={6}>
                  <Grid item md={12}>
                    <LocalEnhancedTable<SysDocuments>
                      refreshGrid={false}
                      columns={columns}
                      data={covidCard}
                    />
                    {fullAccessUser && (
                      <DropZone
                        onFileAccepted={handleCovidCardFileAccepted}
                        accept={{
                          "image/*": [".png", ".jpg", ".jpeg", ".gif", ".bmp"],
                        }}
                        disabled={false}
                        disableMessage="Only five certifications are allowed"
                        submiting={isSubmitting}
                      />
                    )}
                  </Grid>
                  <Grid item md={6}>
                    <Typography variant="subtitle1">
                      <b>*File must be in PNG, JPG, JPEG, GIF or BMP format*</b>
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          )}
        </Grid>
      </Grid>
      <Stack>
        <Stack direction="row" justifyContent="center" spacing={2}>
          <FormButton
            text="Close"
            onClick={handlerCloser}
            variant="outlined"
            size="medium"
          />
          <FormButton
            text="Send by email"
            onClick={() => {
              if (emailSelected.length > 0) {
                setSendbyEmailShow(true);
              } else {
                log.error("Select a document before sending");
              }
            }}
            color="primary"
            size="medium"
          />
          {user?.role === ROLES.SuperAdministrator && (
            <FormButton
              text="Document Issue"
              onClick={() => {
                setDocumentIssueShowPopUp(true);
              }}
              color="warning"
              size="medium"
              endIcon={<SendIcon />}
            />
          )}
        </Stack>
      </Stack>
      {sendbyEmailShow && (
        <ShareByEmailPopUpComponent
          sendbyEmailShow={sendbyEmailShow}
          setSendbyEmailShow={setSendbyEmailShow}
          sendEmailFunction={handleSendEmail}
        />
      )}
      <DialogMessagePopup
        title={"Information"}
        text={"Are you certain you want to delete this item?"}
        showPopup={showDeleteModal}
        setShowPopup={setShowDeleteModal}
        onSave={deleteDocument}
        isSubmitting={false}
        disabled={!disabledButton}
      >
        <Stack>
          <Typography mb={2} mt={5}>
            Type "delete" to confirm
          </Typography>
          <FormText
            label=""
            name="code"
            value={values.code}
            onChange={handleOnChange}
          />
        </Stack>
      </DialogMessagePopup>
      <AddNewPhotoPopUp
        setShowAddPhotoModal={setShowAddPhotoModal}
        showAddPhotoModal={showAddPhotoModal}
        row={currentRowTechnician}
        setRefreshPage={setRefreshPage}
        refreshPage={refreshPage}
      />
      {documentIssueShowPopUp && (
        <DocumentIssuePopUp
          documentIssueShowPopUp={documentIssueShowPopUp}
          setDocumentIssueShowPopUp={setDocumentIssueShowPopUp}
          technicianId={Number(userId)}
        />
      )}
    </>
  );
};

export default PersonalDocuments;
