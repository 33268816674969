import { SidebarItemsType } from "../../types/sidebar";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ApartmentIcon from "@mui/icons-material/Apartment";
import {
  PenTool,
  PieChart,
  Sliders,
  Users,
  Tag,
  Folder,
  Settings,
  Shield,
  CheckCircle,
  Slash,
  Clipboard,
  CreditCard,
  Bookmark,
} from "react-feather";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import { Permission, PermissionTechnician } from "src/ts/enums";
import { userService } from "src/services";
import {
  DriveEtaOutlined,
  Group,
  HomeRepairServiceOutlined,
  Quiz,
  StorageOutlined,
  EngineeringOutlined,
  BusinessCenter,
  ReceiptLong,
  LocalActivity,
  AutoGraph,
  PendingActionsOutlined,
  Brightness7,
  ModelTraining,
} from "@mui/icons-material";
import { PaymentsIcon, PermContactCalendarIcon } from "../icons";
import { ShoppingCartIcon } from "../icons";
import { ROLES } from "src/constants";

const pagesSection = (user: any) => {
  return [
    {
      href: "/app/sysAdminDashboard",
      icon: Sliders,
      title: "Dashboard",
      permissionMatrix: [Permission.SysAdmin],
    },
    {
      href: "/app/SuperAdministratorDashboard",
      icon: Sliders,
      title: "Dashboard",
      permissionMatrix: [
        user?.subscriptionId !== 1 ? Permission.SuperAdministrator : false,
      ],
    },
    {
      href: "/app/auditorDashboard",
      icon: DashboardIcon,
      title: "Dashboard",
      permissionMatrix: [Permission.Auditor],
    },
    {
      href: "/app/energyAuditorDashboard",
      icon: DashboardIcon,
      title: "Dashboard",
      permissionMatrix: [Permission.EnergyAuditor],
    },
    {
      href: "/app/temporalEnergyAuditorDashboard",
      icon: DashboardIcon,
      title: "Dashboard",
      permissionMatrix: [Permission.TemporalEnergyAuditor],
    },
    {
      href: "/app/temporalAuditorDashboard",
      icon: DashboardIcon,
      title: "Dashboard",
      permissionMatrix: [Permission.TemporalAuditor],
    },
    {
      href: "/app/architectDashboard",
      icon: DashboardIcon,
      title: "Dashboard",
      permissionMatrix: [
        Permission.Architect,
        Permission.ArchitectAux,
        user?.subscriptionId === 1 ? Permission.SuperAdministrator : false,
      ],
    },
    {
      href: "/app/companyAdministratorsDashboard",
      icon: DashboardIcon,
      title: "Dashboard",
      permissionMatrix: [Permission.CompanyOwner],
    },
    {
      href: "/app/unionAdministratorDashboard",
      icon: DashboardIcon,
      title: "Dashboard",
      permissionMatrix: [Permission.UnionAdministrator],
    },
    {
      href: "/app/trainerDashboard",
      icon: DashboardIcon,
      title: "Dashboard",
      permissionMatrix: [Permission.Trainer],
    },
    {
      href: "/app/trainingDashboard",
      icon: DashboardIcon,
      title: "Dashboard",
      permissionMatrix: [Permission.Training],
    },
    {
      href: "/app/companyAdministratorsDashboard",
      icon: DashboardIcon,
      title: "Dashboard",
      permissionMatrix: [Permission.Administrator],
    },
    {
      href: "/app/companyAdministratorsDashboard",
      icon: DashboardIcon,
      title: "Dashboard",
      permissionMatrix: [Permission.ProjectManager],
    },
    {
      href: `/app/company/${user?.companyId}`,
      icon: BusinessCenter,
      title: "Company",
      permissionMatrix: [Permission.Company_Edit],
    },
    {
      href: `/app/users/${user?.companyId}`,
      icon: Users,
      title: "Users",
      permissionMatrix: [Permission.Users],
    },
    {
      href: "/app/companies/",
      icon: Users,
      title: "Companies",
      permissionMatrix: [Permission.Companies],
    },
    {
      title: "Auditors/Technicians",
      icon: EngineeringOutlined,
      permissionMatrix: [(Permission.Technicians, Permission.TechniciansAdmin)],
      children: [
        {
          href: `/app/Technicians/${user?.companyId}`,
          title: "Requests",
          permissionMatrix: [Permission.TechniciansAdmin],
        },
        {
          href: `/app/TechniciansAssignments/${user?.companyId}`,
          title: "Assignments",
          permissionMatrix: [Permission.Technicians],
        },
      ],
    },
    {
      href: "/app/Administrators",
      icon: Users,
      title: "Administrators",
      permissionMatrix: [Permission.Administrators],
    },
    {
      href: `/app/Buildings/${user?.companyId}`,
      icon: ApartmentIcon,
      title: "Buildings",
      permissionMatrix: [Permission.Building],
      permissionTechician: PermissionTechnician.canAddBuildingsAndProjects,
    },
    {
      href: `/app/Contractors`,
      icon: PermContactCalendarIcon,
      title: "My Contractors",
      permissionMatrix: [
        Permission.Architect,
        Permission.ArchitectAux,
        user?.subscriptionId === 1 ? Permission.SuperAdministrator : false,
      ],
    },
    {
      href: "/app/Working/",
      icon: ShowChartIcon,
      title: "Projects",
      permissionMatrix: [Permission.ProjectTechnician],
    },
    {
      href: `/app/ProjectList/${user?.companyId}`,
      icon: ShowChartIcon,
      title: "Projects",
      permissionMatrix: [Permission.Project_Admin],
    },
    {
      href: "/app/projectsOutForBid",
      icon: PieChart,
      title: "Projects Out For Bid",
      permissionMatrix: [
        user?.role === ROLES.SysAdmin ||
        user?.role === ROLES.Architect ||
        user?.role === ROLES.ArchitectAux
          ? ""
          : Permission.ProjectOutForBidParent,
      ],
    },
    {
      href: `/app/ProjectEvaluation`,
      icon: AutoGraph,
      title: "Projects Evaluation",
      permissionMatrix: [Permission.ProjecEvaluation],
    },
    {
      href: "/app/ProjectOutToBidSysAdmin",
      icon: PieChart,
      title: "Projects Out For Bid Admin",
      permissionMatrix: [
        user?.role === ROLES.SysAdmin ? Permission.Projects_out_for_bid : "",
      ],
    },
    {
      href: `/app/ProjectArchitect/${user?.companyId}/all`,
      icon: ShowChartIcon,
      title: "Projects",
      permissionMatrix: [Permission.ProjectContest],
    },
    {
      href: `/app/templates`,
      icon: ReceiptLong,
      title: "Templates",
      permissionMatrix: [Permission.Templates],
    },

    {
      href: `/app/UserDocuments/${user?.userId}/${user?.companyId}/1`,
      icon: Folder,
      title: "Personal Documents",
      permissionMatrix: [Permission.Users_Documents],
    },
    {
      href: "/app/exam",
      icon: Quiz,
      title: "Exam",
      permissionMatrix: [Permission.Exam],
    },
    {
      href: `/app/companyAssets/${user?.companyId}/EQIP`,
      icon: HomeRepairServiceOutlined,
      title: "Test Equipment",
      permissionMatrix: [Permission.Equipment],
    },
    {
      href: `/app/companyAssets/${user?.companyId}/VEHI`,
      icon: DriveEtaOutlined,
      title: "Vehicles",
      permissionMatrix: [Permission.Vehicles],
    },
    {
      href: "/app/coupons",
      icon: Tag,
      title: "Coupons",
      permissionMatrix: [Permission.Coupons],
    },
    {
      href: "/app/sysAdminDocuments",
      icon: Folder,
      title: "AMP Documents",
      permissionMatrix: [Permission.AMP_Documents],
    },
    {
      icon: Settings,
      title: "Catalogs",
      permissionMatrix: [Permission.Catalogs],
      children: [
        {
          title: "Company Assets Type",
          href: "/app/CompanyAssetsType",
          permissionMatrix: [Permission.Catalogs],
        },
        {
          title: "Youtube Training Videos",
          href: "/app/YoutubeTrainingVideos",
          permissionMatrix: [Permission.Catalogs],
        },
        {
          title: "System Costs",
          href: "/app/SystemCostsCatalog",
          permissionMatrix: [Permission.Catalogs],
        },
        {
          title: "Units",
          href: "/app/Units",
          permissionMatrix: [Permission.Catalogs],
        },
        {
          title: "MandatoryMinimum",
          href: "/app/MandatoryMinimum",
          permissionMatrix: [Permission.Catalogs],
        },
        {
          title: "Terms And Conditions",
          href: "/app/TermsAndConditions",
          permissionMatrix: [Permission.Catalogs],
        },
        {
          title: "Building",
          children: [
            {
              href: "/app/catalog/BuildingType",
              title: "Building Type",
              permissionMatrix: [Permission.Catalogs],
            },
            {
              href: "/app/BuildingSubtype",
              title: "Building Subtype",
              permissionMatrix: [Permission.Catalogs],
            },
          ],
        },
        {
          title: "Project",
          children: [
            {
              href: "/app/catalog/SafetyEquipment",
              title: "Safety Equipment",
              permissionMatrix: [Permission.Catalogs],
            },
          ],
        },
        {
          title: "Fan",
          children: [
            {
              href: "/app/catalog/FanManufacturer",
              title: "Fan Manufacturer",
              permissionMatrix: [Permission.Catalogs],
            },
            {
              href: "/app/catalog/FanType",
              title: "Fan Type",
              permissionMatrix: [Permission.Catalogs],
            },
            {
              href: "/app/catalog/FanSubType",
              title: "Fan SubType",
              permissionMatrix: [Permission.Catalogs],
            },
            {
              href: "/app/catalog/BeltType",
              title: "Belt Type",
              permissionMatrix: [Permission.Catalogs],
            },
            {
              href: "/app/catalog/FanSheave",
              title: "Fan Sheave",
              permissionMatrix: [Permission.Catalogs],
            },
          ],
        },
        {
          title: "Motor",
          children: [
            {
              href: "/app/catalog/FanMotorManufacturer",
              title: "Fan Motor Manufacturer",
              permissionMatrix: [Permission.Catalogs],
            },
            {
              href: "/app/catalog/MotorManufacturer",
              title: "Motor Manufacturer",
              permissionMatrix: [Permission.Catalogs],
            },
            {
              href: "/app/catalog/MotorSheaveMfg",
              title: "Motor Sheave Mfg.",
              permissionMatrix: [Permission.Catalogs],
            },
            {
              href: "/app/catalog/MotorFrameCategory",
              title: "Motor Frame Category",
              permissionMatrix: [Permission.Catalogs],
            },
          ],
        },
        {
          title: "Pump",
          children: [
            {
              href: "/app/catalog/PumpType",
              title: "Pump Type",
              permissionMatrix: [Permission.Catalogs],
            },
            {
              href: "/app/catalog/PumpSvc",
              title: "Pump Svc",
              permissionMatrix: [Permission.Catalogs],
            },
            {
              href: "/app/catalog/PumpManufacturer",
              title: "Pump Manufacturer",
              permissionMatrix: [Permission.Catalogs],
            },
            {
              href: "/app/catalog/FlowDesign",
              title: "Flow Design",
              permissionMatrix: [Permission.Catalogs],
            },
          ],
        },
        {
          title: "Union",
          children: [
            {
              href: "/app/catalog/LaborUnions",
              title: "Labor Unions",
              permissionMatrix: [Permission.Catalogs],
            },
            {
              href: "/app/LocalUnions",
              title: "Local Unions",
              permissionMatrix: [Permission.Catalogs],
            },
          ],
        },
        {
          title: "Schools",
          href: "/app/Schools",
          permissionMatrix: [
            process.env.REACT_APP_ENVIRONMENT === "SCHOOL" &&
            user?.role === ROLES.SysAdmin
              ? Permission.Catalogs
              : "",
          ],
        },

        {
          title: "Emergency Button",
          children: [
            {
              href: "/app/catalog/TicketClassification",
              title: "Ticket Classification",
              permissionMatrix: [Permission.Catalogs],
            },
          ],
        },
      ],
    },
    {
      icon: CreditCard,
      title: "Wallet Admin Center",
      permissionMatrix: [Permission.Wallet_Admin_Center],
      children: [
        {
          title: "Total Summary",
          href: "/app/Wallet/totalSummary",
        },
        {
          title: "Elavon Payments",
          href: "/app/Wallet/elavonPaymentLog",
        },
        {
          title: "Wallet purchases",
          href: "/app/Wallet/purchaseHistory",
        },
      ],
    },
    {
      href: "/app/quiz",
      icon: PenTool,
      title: "Quiz",
      permissionMatrix: [Permission.Quiz],
      children: [
        {
          href: "/app/quiz",
          title: "Tests",
        },
        {
          href: "/app/questions",
          title: "Questions",
        },
        {
          href: "/app/TechnicianQuizCategory/TechnicianQuizCategory",
          title: "Category",
        },
      ],
    },
    {
      href: "/app/emailBlackList",
      icon: Slash,
      title: "Email Black List",
      permissionMatrix: [Permission.Email_Black_list],
    },
    {
      href: "/app/subscriptions",
      icon: CheckCircle,
      title: "Subscriptions",
      permissionMatrix: [Permission.Subscriptions],
      children: [
        {
          href: "/app/pricing",
          title: "Pricing",
        },
        {
          href: "/app/detail",
          title: "Detail",
        },
      ],
    },
    {
      icon: Shield,
      title: "Plaque & Decals",
      permissionMatrix: [
        Permission.Plaque_Decals,
        Permission.PlaqueDecalsOrders,
      ],

      children: [
        {
          title: "Providers",
          href: "/app/plaqueProviders",
          permissionMatrix: [Permission.Plaque_Decals],
        },
        {
          title: "Orders",
          href: "/app/plaqueOrders",
          permissionMatrix: [Permission.PlaqueDecalsOrders],
        },
      ],
    },
    {
      href: "/app/history",
      icon: Bookmark,
      title: "History",
      permissionMatrix: [Permission.History],
      children: [
        {
          href: "/app/SystemCostsHistory",
          title: "Costs Catalog",
          permissionMatrix: [Permission.HistoryCostCatalog],
        },
        {
          href: "/app/couponsWalletMovement",
          title: "Coupon Usage",
          permissionMatrix: [Permission.HistoryCoupon],
        },
        {
          href: `/app/techniciansHistory/${user?.companyId}`,
          title: "Auditors/Technicians",
          permissionMatrix: [Permission.HistoryAuditorsTechnicians],
        },
        {
          href: `/app/projectHistory/${user?.companyId}`,
          title: "Project",
          permissionMatrix: [Permission.HistoryProject],
        },
        {
          href: `/app/studiesHistory/${user?.companyId}`,
          title: "Studies",
          permissionMatrix: [Permission.HistoryReports],
        },
        {
          href: `/app/userAccessHistory/${user?.companyId}`,
          title: "Users Access",
          permissionMatrix: [Permission.HistoryUsersAccess],
        },
        {
          href: `/app/forwardDocumentLibrary`,
          title: "Documents Notifications",
          permissionMatrix: [Permission.HistoryDocumentsNotifications],
        },
        {
          href: `/app/projectHistoryBid/${user?.companyId}`,
          title: "Project Bids History",
          permissionMatrix: [Permission.Project_Bid_History],
        },
      ],
    },
    {
      href: `/app/Wallet/${user?.companyId}`,
      icon: PaymentsIcon,
      title: "Wallet",
      permissionMatrix: [Permission.Wallet],
    },
    {
      href: `/app/cart/`,
      icon: ShoppingCartIcon,
      title: "Cart",
      permissionMatrix: [Permission.Cart],
    },
    {
      href: `/app/purchase/storage`,
      icon: StorageOutlined,
      title: "Purchase Storage",
      permissionMatrix: [
        process.env.REACT_APP_ENVIRONMENT === "TRAINING"
          ? false
          : Permission.PurchaseStorage,
      ],
    },
    {
      href: `/app/teamMembers/${user?.companyId}`,
      icon: Group,
      title: "Team Members",
      permissionMatrix: [
        process.env.REACT_APP_ENVIRONMENT === "TRAINING"
          ? false
          : Permission.TeamMembers,
      ],
    },
    {
      href: `/app/testimonialHistory`,
      icon: Brightness7,
      title: "Testimonials",
      permissionMatrix: [Permission.Testimonials],
    },
    {
      href: `/app/tickets`,
      icon: LocalActivity,
      title: "Tickets",
      permissionMatrix: [Permission.Assign_Support_Tickets],
    },
    {
      icon: ModelTraining,
      title: "Training Courses",
      href: "/app/catalog/TrainingCourses",
      permissionMatrix: [
        (process.env.REACT_APP_ENVIRONMENT === "SCHOOL" ||
          process.env.REACT_APP_ENVIRONMENT === "TRAINING" ||
          process.env.REACT_APP_ENVIRONMENT === "UNION") &&
        user?.role === ROLES.Trainer
          ? Permission.TrainingCoursesCatalog
          : "",
      ],
    },
    {
      href: "/app/conversions",
      icon: Clipboard,
      title: "Conversions",
      permissionMatrix: [Permission.Conversions],
    },
    {
      href: `/app/TrainingDocuments`,
      icon: Folder,
      title: "Training Documents",
      permissionMatrix: [Permission.TrainingDocuments],
    },
    {
      href: `/app/MyPendingRequests/${user?.companyId}`,
      icon: PendingActionsOutlined,
      title: "My Pending Requests",
      permissionMatrix: [Permission.My_Pending_Requests],
    },
    {
      href: `/app/Project/CreateInfectious`,
      icon: Shield,
      title: "Create Infectious Control Project",
      permissionMatrix: [Permission.Infectious_Control_Project],
      permissionTechician:
        user?.role === ROLES.TemporalAuditor || user?.role === ROLES.Auditor
          ? null
          : PermissionTechnician.canAddBuildingsAndProjects,
    },
  ] as SidebarItemsType[];
};

const getTitle = async (role: string, userName: string): Promise<string> => {
  const titles: Record<string, string> = {
    SysAdmin: "System Administrator",
    SuperAdministrator: "System Administrator",
    Trainer: "Trainer",
    UnionAdministrator: "UNION Administrator",
    Administrator: "Administrator",
    CompanyOwner: "Company Administrator",
    ProjectManager: "Project Manager",
    Architect: "{{vm.authority == '' ? vm.currentUser : vm.authority}}",
    Auditor: "Testing & Balancing Technician",
    TemporalAuditor: "Testing & Balancing Technician (In Process)",
    EnergyAuditor: "Energy Auditor / Infectious Building Control",
    TemporalEnergyAuditor:
      "Energy Auditor / Infectious Building Control (In Process)",
    Training: "Trial / Demo",
    ArchitectAux: "Architect Assistant",
  };

  if (role === "Architect") {
    const response = await userService.getAuthorityByUserName(userName);
    return response.data;
  }
  return titles[role];
};

const navItems = async (role: string, userName: string, user: any) => [
  {
    title: await getTitle(role, userName),
    pages: pagesSection(user),
  },
];
export default navItems;
