import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { NavLink, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import useLog from "src/hooks/useLog";
import { COUNTRIES } from "src/constants";
import {
  CardContent,
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
  Stack,
} from "@mui/material";
import { spacing } from "@mui/system";
import { useForm, Form, useCountry, usePermissions } from "src/hooks";
import {
  FormCancelButton,
  FormSaveButton,
  FormText,
  FormSelectState,
  FormSelectCountry,
  FormMaskText,
  FormSelect,
} from "src/components/formControls";
import userService from "src/services/userService";
import { IUserPost, IUserCountryAccess } from "src/ts/interfaces";
import UserCountryAccessData from "./components/UserCountryAccessData";
import useZipCode from "src/hooks/useZipCode";
import useLoading from "src/hooks/useLoading";
import { UserParams } from "src/ts/types";
import EnableDisableUpdatePopUp from "./components/EnableDisableUpdatePopUp";
import { Validator } from "src/ts/types/Validator";
import { Permission as PermissionTypes } from "src/ts/enums";
import UserSchoolsAccessData from "./components/UserSchoolsAccessData";
import { IUserSchoolsAccess } from "src/ts/interfaces/userSchoolsAccess";

const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const initialValues: IUserPost = {
  name: "",
  lastName: "",
  address: "",
  address2: "",
  countryId: "",
  stateId: "",
  zip: "",
  city: "",
  countryCode: "",
  phone: null,
  extension: "",
  email: "",
  userCountryAccess: [],
  statusId: 1,
  administratorType: 0,
  country: { countryCode: 0 },
  userSchoolAccess: [],
};

const administratorRol = [
  {
    key: 1,
    value: "AMP Administrator",
  },
  {
    key: 2,
    value: "Washington D.C. - Union Administrator - SMWIA/SMART",
  },
];

const administratorSchoolRol = [
  {
    key: 1,
    value: "AMP Administrator",
  },
  {
    key: 3,
    value: "Trainer",
  },
];

const administratorRolCreated = [
  {
    key: 1,
    value: "AMP Administrator",
  },
  {
    key: 2,
    value: "Washington D.C. - Union Administrator - SMWIA/SMART",
  },
  {
    key: 3,
    value: "Trainer",
  },
  {
    key: 4,
    value: "System Administrator",
  },
];

const Administrator = () => {
  let navigate = useNavigate();
  const { log } = useLog();
  let { id } = useParams<UserParams>();
  const userId = parseInt(id === undefined ? "0" : id);

  const validate = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };

    temp.administratorType = new Validator(fieldValues, "administratorType")
      .selectedOption(0, "The role is required.")
      .toString();

    if (fieldValues.hasOwnProperty("name")) {
      if (fieldValues.name === "") {
        temp.name = "This field is required";
      } else if (fieldValues.name.length > 50) {
        temp.name = "Only 50 character are allowed";
      } else {
        temp.name = "";
      }
    }

    if (fieldValues.hasOwnProperty("address")) {
      if (fieldValues.address.length > 200) {
        temp.address = "Only 200 character are allowed";
      } else {
        temp.address = "";
      }
    }

    if (fieldValues.hasOwnProperty("address2")) {
      if (fieldValues?.address2 && fieldValues.address2.length > 200) {
        temp.address2 = "Only 200 character are allowed";
      } else {
        temp.address2 = "";
      }
    }

    if (fieldValues.hasOwnProperty("zip")) {
      if (fieldValues.zip === "") {
        temp.zip = "The zipcode is required";
      } else {
        temp.zip = "";
      }
    }

    if (fieldValues.hasOwnProperty("city")) {
      if (fieldValues.city === "") {
        temp.city = "The city is required";
      } else {
        temp.city = "";
      }
    }

    if (fieldValues.hasOwnProperty("statusId"))
      temp.statusId =
        fieldValues.statusId !== 0 ? "" : "This field is required.";

    if (fieldValues.hasOwnProperty("email")) {
      const regex =
        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i; // eslint-disable-line
      if (fieldValues.email === "") {
        temp.email = "The email is required";
      } else if (regex.test(fieldValues.email) === false) {
        temp.email = "The email is not valid";
      } else {
        temp.email = "";
      }
    }

    temp.phone = new Validator(fieldValues, "phone")
      .isRequired("The User's cell phone is required.")
      .phoneFormat("Incorrect phone format.")
      .toString();

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const [countryAccessData, setcountryAccessData] = useState<
    IUserCountryAccess[]
  >([]);
  const [schoolsAccessData, setSchoolsAccessData] = useState<
    IUserSchoolsAccess[]
  >([]);

  const [dataCountryAccess, setDataCountryAccess] =
    useState<IUserCountryAccess[]>();
  const [dataSchoolsAccess, setDataSchoolsAccess] =
    useState<IUserSchoolsAccess[]>();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [isCountryLoading, setIsCountryLoading] = useState(false);
  const [isStateLoading, setIsStateLoading] = useState(false);
  const [stateUser, setStateUser] = useState(0);
  const [countryCodeUser, setCountryCodeUser] = useState(0);
  const [showPopUp, setShowPopUp] = useState(false);
  const { fullAccess, readOnly } = usePermissions(
    PermissionTypes.Administrators
  );
  const { values, setValues, errors, setErrors, handleInputChange } = useForm(
    initialValues,
    false,
    validate
  );
  const { isLoading, startRequest, endRequest } = useLoading();
  const { countries } = useCountry();
  const { zipValue } = useZipCode(values.zip);

  useEffect(() => {
    const code = countries.filter((item: any) => {
      return item.id === values.countryId;
    });

    if (userId === 0 && code.length > 0) {
      setValues({
        ...values,
        countryCode: code[0].countryCode,
      });
    } else if (userId !== 0 && code.length > 0) {
      setValues({
        ...values,
        countryCode:
          countryCodeUser === values.country.countryCode
            ? values.country.countryCode
            : code[0].countryCode,
      });
    } else {
      setValues({
        ...values,
        countryCode: values.country.countryCode ?? "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.countryId, setValues]);

  useEffect(() => {
    if (
      zipValue?.countryId !== undefined &&
      values.countryId === COUNTRIES.USA
    ) {
      setValues({ ...values, city: zipValue.city, stateId: zipValue.stateId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zipValue]);

  useEffect(() => {
    const getUser = async (userId: number) => {
      startRequest("user");
      const response = await userService.getById(userId);
      setValues(response.data);
      setStateUser(response.data.statusId);
      setCountryCodeUser(response.data.country.id);
      endRequest("user");
    };

    if (Number(id) > 0) {
      getUser(Number(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleCancel = () => {
    navigate("/app/administrators");
  };

  const handleSave = async () => {
    if (!validate()) return;

    if (
      ((dataSchoolsAccess?.length === 0 ||
        dataSchoolsAccess?.length === undefined) &&
        values.administratorType !== 4 &&
        values.id !== undefined &&
        process.env.REACT_APP_ENVIRONMENT === "SCHOOL") ||
      ((schoolsAccessData?.length === 0 ||
        schoolsAccessData?.length === undefined) &&
        values.administratorType !== 4 &&
        values.id === undefined &&
        process.env.REACT_APP_ENVIRONMENT === "SCHOOL")
    ) {
      log.error(
        "At least one value needs to be selected for super administrator schools access permission"
      );
      return;
    }
    if (
      ((dataCountryAccess?.length === 0 ||
        dataCountryAccess?.length === undefined) &&
        values.administratorType !== 4 &&
        values.id !== undefined) ||
      ((countryAccessData?.length === 0 ||
        countryAccessData?.length === undefined) &&
        values.administratorType !== 4 &&
        values.id === undefined)
    )
      log.error(
        "At least one value needs to be selected for super administrator country access permission"
      );
    else {
      try {
        setIsSubmitting(true);

        if (userId !== 0) {
          if (stateUser !== values.statusId) {
            setShowPopUp(true);
          } else {
            await userService.update(values.id, true, values);
            log.success("Data was update");
          }
        } else {
          values.userCountryAccess = countryAccessData;
          values.userSchoolAccess = countryAccessData;
          await userService.create(values);
          log.success("User was created");
          navigate("/app/administrators");
        }
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  const statuses = [
    { key: 1, value: "Enable" },
    { key: 2, value: "Disable" },
  ];

  const row = 6;

  return (
    <>
      <Helmet title="Administrators" />
      <Typography variant="h3" gutterBottom display="inline">
        Administrator
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Dashboard
        </Link>
        <Link component={NavLink} to="/app/administrators">
          Administrators
        </Link>
        <Typography>Administrator</Typography>
      </Breadcrumbs>

      <Divider my={6} />
      <Card mb={6}>
        <CardContent>
          <Form>
            <Grid container spacing={5}>
              <Grid item xs={row}>
                <FormSelect
                  name="administratorType"
                  label="Administrator"
                  value={values.administratorType}
                  onChange={handleInputChange}
                  options={
                    id === "0"
                      ? process.env.REACT_APP_ENVIRONMENT === "SCHOOL"
                        ? administratorSchoolRol
                        : administratorRol
                      : administratorRolCreated
                  }
                  error={errors.administratorType}
                  disabled={readOnly || userId !== 0 ? true : false}
                />
              </Grid>
              <Grid item xs={row}>
                <FormText
                  name="name"
                  label="First Name"
                  value={values.name}
                  onChange={handleInputChange}
                  error={errors.name}
                  disabled={isSubmitting || readOnly}
                  showSkeleton={isLoading}
                />
              </Grid>
              <Grid item xs={row}>
                <FormText
                  name="lastName"
                  label="Last Name"
                  value={values.lastName}
                  onChange={handleInputChange}
                  error={errors.lastName}
                  disabled={isSubmitting || readOnly}
                  showSkeleton={isLoading}
                />
              </Grid>
              <Grid item xs={row}>
                <FormText
                  name="address"
                  label="Address"
                  value={values.address}
                  onChange={handleInputChange}
                  error={errors.address}
                  disabled={isSubmitting || readOnly}
                  showSkeleton={isLoading}
                />
              </Grid>
              <Grid item xs={row}>
                <FormText
                  name="address2"
                  label="Address 2"
                  value={values.address2}
                  onChange={handleInputChange}
                  error={errors.address2}
                  disabled={isSubmitting || readOnly}
                  showSkeleton={isLoading}
                />
              </Grid>
              <Grid item xs={row}>
                <FormSelectCountry
                  value={values.countryId}
                  onChange={handleInputChange}
                  isLoading={(value: boolean) => setIsCountryLoading(value)}
                  error={errors.countryId}
                  disable={isSubmitting || readOnly}
                />
              </Grid>
              <Grid item xs={row}>
                <FormText
                  name="zip"
                  label="Zip Code"
                  value={values.zip}
                  onChange={handleInputChange}
                  error={errors.zip}
                  disabled={isSubmitting || readOnly}
                  maxLength={6}
                  showSkeleton={isLoading}
                />
              </Grid>
              <Grid item xs={row}>
                <FormText
                  name="city"
                  label="City"
                  value={values.city}
                  onChange={handleInputChange}
                  error={errors.city}
                  disabled={isSubmitting || readOnly}
                  showSkeleton={isLoading}
                />
              </Grid>
              <Grid item xs={row}>
                {values.countryId === COUNTRIES.USA ? (
                  <FormSelectState
                    stateId={values.stateId}
                    stateName={values.stateName}
                    countryId={values.countryId}
                    errorsStateId={errors.stateId}
                    errorsStateName={errors.stateName}
                    onChange={handleInputChange}
                    isLoading={(value: boolean) => setIsStateLoading(value)}
                    disable={isSubmitting || readOnly}
                  />
                ) : (
                  <FormText
                    name="stateName"
                    label="State"
                    value={values.stateName}
                    onChange={handleInputChange}
                    error={errors.stateName}
                    disabled={isSubmitting || readOnly}
                    showSkeleton={isLoading}
                  />
                )}
              </Grid>
              <Grid item xs={row}>
                <Stack direction="row">
                  <Grid container>
                    <Grid item xs={1.5}>
                      <FormText
                        name="countryCode"
                        label="Code"
                        value={values.countryCode}
                        disabled={true}
                      />
                    </Grid>
                    <Grid item xs={10.5}>
                      <FormMaskText
                        name="phone"
                        mask="(999) 999 - 9999"
                        label="Cell Phone"
                        onChange={handleInputChange}
                        value={values.phone}
                        error={errors.phone}
                        disabled={readOnly}
                      />
                    </Grid>
                  </Grid>
                </Stack>
              </Grid>
              <Grid item xs={row}>
                <FormText
                  name="email"
                  label="Email"
                  value={values.email}
                  onChange={handleInputChange}
                  error={errors.email}
                  disabled={userId !== 0 || isSubmitting || readOnly}
                  showSkeleton={isLoading}
                />
              </Grid>
              <Grid item xs={row}>
                <FormSelect
                  name="statusId"
                  label="Status"
                  value={values.statusId}
                  onChange={handleInputChange}
                  options={statuses}
                  error={errors.statusId}
                  disable={isSubmitting || readOnly}
                  showSkeleton={isLoading}
                />
              </Grid>
              {values.administratorType !== 4 && (
                <>
                  <Grid
                    item
                    xs={process.env.REACT_APP_ENVIRONMENT === "SCHOOL" ? 6 : 12}
                  >
                    <Divider my={6} />
                    <UserCountryAccessData
                      userId={userId}
                      userCountryAccessData={countryAccessData}
                      setUserCountryAccessData={setcountryAccessData}
                      setDataCountryAccess={setDataCountryAccess}
                      disabled={readOnly}
                    />
                  </Grid>
                  {process.env.REACT_APP_ENVIRONMENT === "SCHOOL" && (
                    <Grid item xs={6}>
                      <Divider my={6} />
                      <UserSchoolsAccessData
                        userId={userId}
                        userSchoolsAccessData={schoolsAccessData}
                        setUserSchoolsAccessData={setSchoolsAccessData}
                        setDataSchoolsAccess={setDataSchoolsAccess}
                        disabled={readOnly}
                      />
                    </Grid>
                  )}
                </>
              )}

              <Grid item xs={12}>
                <Stack
                  direction="row"
                  spacing={2}
                  justifyContent="center"
                  alignItems="center"
                >
                  <FormCancelButton
                    onClick={handleCancel}
                    isSubmitting={isSubmitting}
                    disabled={isCountryLoading || isStateLoading}
                  />
                  {fullAccess && (
                    <FormSaveButton
                      onClick={handleSave}
                      isSubmitting={isSubmitting}
                      disabled={isCountryLoading || isStateLoading}
                      showSkeleton={isLoading}
                    />
                  )}
                </Stack>
              </Grid>
            </Grid>
          </Form>
        </CardContent>
      </Card>
      <EnableDisableUpdatePopUp
        showPopUp={showPopUp}
        setShowPopUp={setShowPopUp}
        name={values.name}
        enableUser={values.statusId === 1 ? false : true}
        userID={userId}
        valuesOther={values}
      />
    </>
  );
};

export default Administrator;
